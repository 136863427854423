/* eslint-disable react/no-unknown-property */
/* eslint-disable prettier/prettier */
import React, {
	ChangeEvent,
	useEffect,
	useState,
} from "react";
import { Link, navigate } from "gatsby";
import scrollTo from "gatsby-plugin-smoothscroll";
import tw from "twin.macro";

import StateDropdown from "../forms/state-dropdown";
import DtcFactsBox from "../map/dtc-facts-box";
import CheckedCircle from "../../assets/svgs/checked-circle.inline.svg";
import MapInteractive from "../map/map-interactive";
import { useStateStore } from "../../util/store/state-store";

import numberOfCraftDistillersPerState from "../../util/data/number-of-craft-distillers";

import { StateDistrict } from "../../util/data/states";
import MapLegend from "../../assets/svgs/map-legend.inline.svg";

const InteractiveMapSection = ({
	isActive,
	pdf,
}: {
	isActive: boolean;
	pdf?: string | null;
}) => {
	const [selected, setSelected] =
		useState<StateDistrict | null>(null);

	const { currentState } = useStateStore();
	const short = currentState?.abbrv as StateDistrict;

	const handleChange = (
		e: ChangeEvent<HTMLSelectElement>
	) => {
		setSelected(e.target.value as StateDistrict);
	};

	useEffect(() => {
		if (currentState) {
			if (window.innerWidth < 1024) {
				scrollTo(`#interactive-map`);
			} else {
				scrollTo(`#state-data`);
			}
		} else {
			scrollTo(`#interactive-map`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (selected) {
			navigate(`/state-facts?state=${selected}`);
		}
	}, [selected]);

	return (
		<div
			id="interactive-map"
			tw="px-6 pt-6 pb-8 bg-mist font-display lg:p-12"
		>
			<div tw="lg:(border-width[1px] border-teal p-2)">
				<div tw="lg:(border-width[1px] border-teal flex justify-center px-24 pt-8)">
					<div tw="lg:(max-w-landing-page w-full)">
						<div
							css={[
								tw`pb-8 border-bottom-width[1px] border-dark-teal border-dashed lg:(pb-5)`,
								!currentState && tw`border-bottom-width[0px]`,
							]}
						>
							<div tw="hidden lg:(flex flex-col items-center)">
								<div tw="flex w-full lg:height[23px]">
									<MapLegend tw="max-w-landing-page" />
								</div>
								<MapInteractive />
							</div>

							<div
								id="state-data"
								tw="lg:(flex justify-between items-end max-height[200px])"
								css={[!currentState && tw`hidden`]}
							>
								<div
									css={[
										tw`flex flex-col items-start lg:justify-between`,
									]}
								>
									<div tw="w-4/5 hidden lg:(mb-6 block)">
										<StateDropdown
											colorTheme="state-facts"
											handleChange={(e) => handleChange(e)}
											hideButton
										/>
									</div>
									<>
										<div
											css={[
												tw`flex items-center`,
												!isActive && tw`hidden`,
											]}
										>
											<CheckedCircle tw="mr-2" />
											<h3 tw="text-bourbon font-sticker">
												Active Campaign
											</h3>
										</div>
										<h1
											css={[
												tw`text-dark-teal font-sticker text-4xl mt-2 mb-8 lg:mb-0`,
											]}
										>
											{currentState?.name}
										</h1>
									</>
								</div>
								{currentState && pdf && (
									<a
										href={pdf}
										download={`Ship My Spirits State Fact Sheet_${currentState?.abbrv}`}
										css={[
											tw`uppercase text-light-beige bg-bourbon px-3 pt-3 pb-1.5 rounded-xl font-medium  lg:(min-height[40px])`,
										]}
									>
										Download the PDF
									</a>
								)}
							</div>
						</div>
						{currentState && (
							<>
								<div
									css={[
										tw`text-bitters py-8 border-bottom-width[1px] border-dark-teal border-dashed md:(flex justify-between pt-10 pb-0)`,
										!currentState &&
											tw`border-bottom-width[0px]`,
									]}
								>
									<DtcFactsBox
										showHint={!!currentState.spiritsText}
									/>
									<div tw="md:width[45%] lg:text-right">
										<h1 tw="font-alternate text-5xl lg:text-7xl">
											{currentState?.jobs
												.toLocaleString()
												.replace(
													/\B(?=(\d{3})+(?!\d))/g,
													`,`
												)}
										</h1>
										<h3 tw="text-dark-teal font-medium text-lg mb-12">
											Spirits Supported Jobs
										</h3>
										<h1 tw="font-alternate text-5xl lg:text-7xl">
											{`$${currentState?.gdp.replace(
												/\B(?=(\d{3})+(?!\d))/g,
												`,`
											)}`}
										</h1>
										<h3 tw="text-dark-teal font-medium text-lg mb-12">
											Spirits Economic Impact
										</h3>
										<h1 tw="font-alternate text-5xl lg:text-7xl">
											{`${numberOfCraftDistillersPerState[short]}`}
										</h1>
										<h3 tw="text-dark-teal font-medium text-lg">
											Craft Distillers
										</h3>
									</div>
								</div>
								<div tw="text-bitters py-8 ">
									{isActive && (
										<p tw="text-2xl font-medium mb-8">
											{currentState?.name} is currently
											considering DTC legislation.
										</p>
									)}
									<Link
										to={`/take-action/${currentState?.abbrv}`}
										tw="bg-teal text-light-beige uppercase font-medium px-12 pb-1.5 pt-3 rounded mt-6"
									>
										Take Action Today!
									</Link>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default InteractiveMapSection;
